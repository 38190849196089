import { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import qs from 'qs'
import 'lazysizes'

import png1x1 from './placeholders/1x1.png'
import webp1x1 from './placeholders/1x1.webp'
import jpg1x1 from './placeholders/1x1.jpg'

import * as styles from './Image.module.scss'

const placeholders = {
  'image/jpeg': jpg1x1,
  'image/webp': webp1x1,
  'image/png': png1x1,
}

const Image = ({ type, src, alt, className, fullWidth, srcSets, ...props }) => {
  const classes = classNames(fullWidth && styles.fullWidth, className, 'lazyload')

  if (!src.includes('//images.ctfassets.net')) {
    return <img src={png1x1} className={classes} data-src={src} alt={alt} {...props} />
  }

  return (
    <picture>
      {srcSets ? (
        srcSets.map((srcSet, index) => {
          const urlParams = `${qs.stringify(
            { w: srcSet.width || null, h: srcSet.height || null },
            { skipNulls: true }
          )}&`

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`srcset-${index}`}>
              <source
                srcSet={webp1x1}
                data-srcset={`${src}?${urlParams}fm=webp&q=80`}
                type="image/webp"
                media={srcSet.media}
              />
              <source
                srcSet={placeholders[type]}
                data-srcset={`${src}?${urlParams}q=80`}
                type={type}
                media={srcSet.media}
              />
            </Fragment>
          )
        })
      ) : (
        <Fragment>
          <source srcSet={webp1x1} data-srcset={`${src}?fm=webp&q=80`} type="image/webp" />
          <source srcSet={placeholders[type]} data-srcset={`${src}?q=80`} type={type} />
        </Fragment>
      )}
      <img
        className={classes}
        src={src}
        srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        data-srcset={`${src}?q=80`}
        alt={alt}
        {...props}
      />
    </picture>
  )
}

Image.propTypes = {
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  srcSets: PropTypes.array,
}

Image.defaultProps = {
  fullWidth: false,
}

export { Image }
export default Image
