import { forwardRef, createElement, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useId } from '@reach/auto-id'
import { Option } from './components/option'
import * as styles from './Select.module.scss'

const Select = forwardRef(
  ({ id, multiple, label, disabled, error, onFocus, onBlur, onChange, ...props }, ref) => {
    const fieldId = useId(id)
    const inputId = `field-${fieldId}-input`
    const labelId = `field-${fieldId}-label`
    const errorId = `field-${fieldId}-error`
    const [focused, setFocused] = useState(false)
    const handleFocus = useCallback(
      event => {
        setFocused(true)
        if (onFocus) {
          onFocus(event)
        }
      },
      [onFocus]
    )

    const handleBlur = useCallback(
      event => {
        setFocused(false)

        if (onBlur) {
          onBlur(event)
        }
      },
      [onBlur]
    )

    const handleChange = useCallback(
      event => {
        if (onChange) {
          onChange(event)
        }
      },
      [onChange]
    )

    const describedBy = []
    if (error) {
      describedBy.push(errorId)
    }
    // any help text goes here

    const labelledBy = []
    // any prefix or suffix goes here
    labelledBy.unshift(labelId)

    const inputClasses = classNames(styles.Input)
    const select = createElement('select', {
      ref,
      id: inputId,
      className: inputClasses,
      disabled,
      'aria-describedby': describedBy.length ? describedBy.join(' ') : undefined,
      'aria-labelledby': labelledBy.join(' '),
      'aria-invalid': Boolean(error),
      'data-qa': 'select',
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange,
      ...props,
    })

    return (
      <div
        className={classNames(
          styles.Select,
          error && styles.error,
          disabled && styles.disabled,
          focused && styles.focused
        )}
      >
        <div className={styles.InnerWrapper}>
          {select}
          <label aria-hidden id={labelId} htmlFor={inputId} className={classNames(styles.Label)}>
            {label}
          </label>
          <FontAwesomeIcon icon={faCaretDown} className={styles.Caret} />
        </div>

        {typeof error === 'string' && (
          <p id={errorId} aria-hidden className={styles.ErrorText}>
            {error}
          </p>
        )}
      </div>
    )
  }
)

Select.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

Select.Option = Option

export { Select }
export default Select
