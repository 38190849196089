/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import isEmpty from 'lodash/isEmpty'
import * as styles from './ElevatedTitleCentered.module.scss'
import { Container } from '../../grid'
import { RichText } from '../../rich-text'
import { Heading } from '../../typography/Heading'
import { Eyebrow } from '../../typography/Eyebrow'
import { Link } from '../../typography/Link'
import { Button } from '../../button'
import { Image } from '../../image'

/* eslint-disable-next-line */
const ButtonElement = ({ buttonCta, buttonUrl, buttonColor, isModal, modalUrlIsMember, modalUrlNonMember,
  buttonTarget = '_self',
  ...props
}) => {
  if (buttonColor === 'light') {
    if (isModal) {
      return (
        <Button
          variant="secondary"
          target={buttonTarget}
          isModal={isModal}
          {...props}
          modalUrlIsMember={modalUrlIsMember}
          modalUrlNonMember={modalUrlNonMember}
        >
          {buttonCta}
        </Button>
      )
    }
    return (
      <Button
        url={buttonUrl}
        variant="secondary"
        target={buttonTarget}
        isModal={isModal}
        {...props}
      >
        {buttonCta}
      </Button>
    )
  }
  if (isModal) {
    return (
      <Button
        isModal={isModal}
        target={buttonTarget}
        {...props}
        modalUrlIsMember={modalUrlIsMember}
        modalUrlNonMember={modalUrlNonMember}
      >
        {buttonCta}
      </Button>
    )
  }

  return (
    <Button url={buttonUrl} target={buttonTarget} {...props}>
      {buttonCta}
    </Button>
  )
}

const ElevatedTitleCentered = ({
  richTagline,
  richHeadline,
  richBody,
  buttonUrl,
  buttonCta,
  buttonColor,
  buttonAlt,
  linkUrl,
  linkCta,
  linkAlt,
  backgroundImage,
  modalUrlIsMember,
  modalUrlNonMember,
  isModal,
}) => {
  const taglineHeadingLevel = 2
  const headlineHeadingLevel = !richTagline ? taglineHeadingLevel : taglineHeadingLevel + 1
  const imgSrcSets = [
    {
      width: 1400,
      media: '(min-width: 601px)',
    },
    {
      width: 600,
      media: '(max-width: 600px)',
    },
  ]

  return (
    <div className={styles.TitleModule}>
      <Container>
        {backgroundImage && (
          <Image
            className={styles.backgroundImg}
            type={backgroundImage.contentType}
            src={backgroundImage.url}
            alt=""
            srcSets={imgSrcSets}
          />
        )}
        <div
          className={backgroundImage ? styles.innerWithBackground : styles.inner}
          data-qa="elevated-title-module-container"
        >
          {richTagline && (
            <RichText
              overrides={{
                paragraph: (node, children) =>
                  !isEmpty(children) && (
                    <Eyebrow
                      element={`h${taglineHeadingLevel}`}
                      className={styles.tagline}
                      data-qa="elevated-title-module-eyebrow"
                    >
                      {children}
                    </Eyebrow>
                  ),
              }}
            >
              {richTagline}
            </RichText>
          )}

          {richHeadline && (
            <RichText
              overrides={{
                paragraph: (node, children) =>
                  !isEmpty(children) && (
                    <Heading
                      element={
                        richTagline && !richBody
                          ? `h${headlineHeadingLevel}`
                          : `h${headlineHeadingLevel}`
                      }
                      size={3}
                      className={styles.heading}
                      data-qa="elevated-title-module-title"
                    >
                      {children}
                    </Heading>
                  ),
              }}
            >
              {richHeadline}
            </RichText>
          )}

          {richBody && (
            <div data-qa="elevated-title-module-copy">
              <RichText>{richBody}</RichText>
            </div>
          )}

          {buttonCta && buttonUrl && (
            <div className={styles.ctaContainer}>
              <ButtonElement
                buttonColor={buttonColor}
                buttonUrl={buttonUrl}
                buttonCta={buttonCta}
                nonGatsbySite={buttonUrl.includes('?')}
                alt={buttonAlt}
                data-qa="elevated-title-module-cta-button"
                modalUrlIsMember={modalUrlIsMember}
                isModal={isModal}
                modalUrlNonMember={modalUrlNonMember}
              />
            </div>
          )}

          {linkUrl && linkCta && (
            <div className={styles.textCtaContainer}>
              <Link
                to={linkUrl}
                alt={linkAlt}
                nonGatsbySite={linkUrl.includes('?')}
                data-qa="elevated-title-module-cta-link"
              >
                {linkCta}
              </Link>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

ElevatedTitleCentered.propTypes = {
  richHeadline: PropTypes.object,
  richTagline: PropTypes.object,
  richBody: PropTypes.object,
  buttonUrl: PropTypes.string,
  buttonCta: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonAlt: PropTypes.string,
  linkUrl: PropTypes.string,
  linkCta: PropTypes.string,
  linkAlt: PropTypes.string,
  backgroundImage: PropTypes.object,
  isModal: PropTypes.bool,
  modalUrlIsMember: PropTypes.string,
  modalUrlNonMember: PropTypes.string,
}

export { ElevatedTitleCentered }
export default ElevatedTitleCentered
