import PropTypes from 'prop-types'

const Option = ({ value, children, ...props }) => {
  return (
    <option value={value} {...props}>
      {children}
    </option>
  )
}

Option.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export { Option }
export default Option
